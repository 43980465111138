import React, { useMemo, useState } from "react"
import ButtonCollapse from "./ButtonCollapse"
import ButtonRefresh from "./ButtonRefresh"
import InputSearch from "./InputSearch"
import LoadingCard from "./LoadingCard"
import AdCardItem from "./AdCardItem"

export default function AdCard({ ads, refreshAds, deleteAd, loaded }) {
  // State
  const [search, setSearch] = useState("")

  // Comportements
  const filterAds = (ads, search) => {
    return ads.filter((ad) => ad.name.toLowerCase().includes(search))
  }

  const adsFiltered = useMemo(() => filterAds(ads, search), [ads, search])

  // Rendu
  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">Ressources</h3>
        <div className="card-tools">
          <InputSearch value={search} onChange={(event) => setSearch(event.target.value.toLowerCase())} />
          <ButtonRefresh onClick={refreshAds} />
          <ButtonCollapse />
        </div>
      </div>
      <div className="card-body p-0" style={{ display: "block" }}>
        <LoadingCard loaded={loaded}>
          {adsFiltered.length > 0 ? (
            <ul className="users-list clearfix">
              {adsFiltered.map((ad) => (
                <AdCardItem ad={ad} key={ad.id} deleteAd={deleteAd} />
              ))}
            </ul>
          ) : (
            <div className="alert alert-info m-3">
              <i className="icon fas fa-info" /> Aucune ressource trouvée
            </div>
          )}
        </LoadingCard>
      </div>
    </div>
  )
}
