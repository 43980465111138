import React, { useMemo, useState } from "react"
import { Link, useParams } from "react-router-dom"
import ContentHeader from "../components/ContentHeader"
import ContentMain from "../components/ContentMain"
import ContentWrapper from "../components/ContentWrapper"
import AdCard from "../components/AdCard"
import AdDropzone from "../components/AdDropzone"
import AdPlayerCard from "../components/AdPlayerCard"
import API from "../utils/API"

export default function MainAd() {
  // State
  let { adId } = useParams()
  const [ads, setAds] = useState([])
  const [loaded, setLoaded] = useState(false)

  // Comportements
  const getAds = () => {
    API.GET("/ad", (response) => {
      setAds(response.data)
      setLoaded(true)
    })
  }

  useMemo(() => {
    getAds()
  }, [])

  const deleteAd = (id) => {
    let adsCopy = ads.filter((ad) => ad.id !== id)
    setAds(adsCopy)
  }

  // Rendu
  return (
    <ContentWrapper>
      <ContentHeader>
        <div className="col-sm-6">
          <h1 className="m-0">Mes ressources</h1>
        </div>
        <div className="col-sm-6">
          <ol className="breadcrumb float-sm-right">
            <li className="breadcrumb-item">
              <Link to={"/"}>Accueil</Link>
            </li>
            <li className="breadcrumb-item active">Mes ressources</li>
          </ol>
        </div>
      </ContentHeader>
      <ContentMain>
        {adId ? (
          <AdPlayerCard ads={ads} adId={adId} deleteAd={deleteAd} loaded={loaded} />
        ) : (
          <>
            <div className="row">
              <div className="col">
                <AdDropzone refreshAds={getAds} />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <AdCard ads={ads} refreshAds={getAds} deleteAd={deleteAd} loaded={loaded} />
              </div>
            </div>
          </>
        )}
      </ContentMain>
    </ContentWrapper>
  )
}
