import React, { useMemo, useState } from "react"
import API from "../utils/API"
import Sweetalert from "../utils/Sweetalert"
import Select from "./Select"

export default function TotemManageDetailsForm({ totem, teams, updateTotem, setEditTotem }) {
  const [totemName, setTotemName] = useState("")
  const [totemAddress, setTotemAddress] = useState("")
  const [totemIsLed, setTotemIsLed] = useState("")
  const [totemWidth, setTotemWidth] = useState("")
  const [totemHeight, setTotemHeight] = useState("")
  const [totemHasNav, setTotemHasNav] = useState(false)
  const [totemNavTeamId, setTotemNavTeamId] = useState("")
  const [totemLink, setTotemLink] = useState("")
  const [totemAwakeTime, setTotemAwakeTime] = useState(0)
  const [powerOffTime, setPowerOffTime] = useState("00:00")
  const [powerOnTime, setPowerOnTime] = useState("00:00")
  const [powerOnOffAdvanced, setPowerOnOffAdvanced] = useState(false)
  const [powerOffTimeArray, setPowerOffTimeArray] = useState([
    "00:00",
    "00:00",
    "00:00",
    "00:00",
    "00:00",
    "00:00",
    "00:00",
  ])
  const [powerOnTimeArray, setPowerOnTimeArray] = useState([
    "00:00",
    "00:00",
    "00:00",
    "00:00",
    "00:00",
    "00:00",
    "00:00",
  ])
  const [error, setError] = useState("")

  // Comportements
  useMemo(() => {
    setTotemName(totem.name)
    setTotemAddress(totem.address || "")
    setTotemIsLed(totem.isLed)
    setTotemWidth(totem.width || "")
    setTotemHeight(totem.height || "")
    setTotemHasNav(totem.hasNav)
    setTotemNavTeamId(totem.navTeamId)
    setTotemLink(totem.link || "")
    setTotemAwakeTime(totem.awakeTime)
    if (totem.powerOffTime.length > 5 && totem.powerOnTime.length > 5) {
      // Advanced
      setPowerOnOffAdvanced(true)
      setPowerOffTimeArray(totem.powerOffTime.split("."))
      setPowerOnTimeArray(totem.powerOnTime.split("."))
    } else {
      // Simple
      setPowerOffTime(totem.powerOffTime)
      setPowerOnTime(totem.powerOnTime)
    }
  }, [totem])

  const clubs = useMemo(() => {
    return teams.filter((team) => team.establishmentType && team.establishmentType.id === 2) // filtre sur les clubs
  }, [teams])

  const updateAdvancedTimeOff = (index, value) => {
    let powerOffTimeArrayCopy = [...powerOffTimeArray]
    powerOffTimeArrayCopy[index] = value
    setPowerOffTimeArray(powerOffTimeArrayCopy)
  }

  const updateAdvancedTimeOn = (index, value) => {
    let powerOnTimeArrayCopy = [...powerOnTimeArray]
    powerOnTimeArrayCopy[index] = value
    setPowerOnTimeArray(powerOnTimeArrayCopy)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setError("")

    Sweetalert.fireConfirm(
      "Validez vous cette mise à jour ?",
      "Le totem se mettra à jour automatiquement, instantanément ou à sa prochaine reconnexion",
      () => {
        let pOffTime = powerOffTime
        let pOnTime = powerOnTime
        if (powerOnOffAdvanced) {
          pOffTime = powerOffTimeArray.join(".")
          pOnTime = powerOnTimeArray.join(".")
        }
        let data = {
          name: totemName,
          address: totemAddress !== "" ? totemAddress : null,
          isLed: totemIsLed,
          width: totemWidth,
          height: totemHeight,
          hasNav: totemHasNav,
          navTeamId: totemNavTeamId,
          link: totemLink !== "" && totem.establishmentTypeId === 1 ? totemLink : null,
          awakeTime: totemAwakeTime,
          powerOffTime: pOffTime,
          powerOnTime: pOnTime,
        }

        API.PUT("/admin/totem/" + totem.id, data, (response) => {
          Sweetalert.fireSuccessToast(response.data.message)
          updateTotem(data)
          setEditTotem(false)
        })
      }
    )
  }

  // Rendu
  return (
    <div className="card card-light">
      <div className="card-header">
        <h3 className="card-title">Édition de l'écran : {totem.id}</h3>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="card-body">
          <div className="row">
            <div className="col">
              <div className="form-group">
                <label>Nom de l'écran</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Nom"
                  value={totemName}
                  required
                  pattern="[a-zA-Z0-9\-áàâäçéèêëíìîïñóòôöúùûüýÿæœ ]{1,255}"
                  title="Chiffres, lettres, espaces ou tiret (-)"
                  onChange={(event) => {
                    setTotemName(event.target.value)
                  }}
                />
              </div>
              <div className="form-group">
                <label>Adresse de l'écran (optionnel)</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Adresse"
                  value={totemAddress}
                  pattern="[a-zA-Z0-9áàâäçéèêëíìîïñóòôöúùûüýÿæœ, ]{1,255}"
                  title="Chiffres, lettres, espaces ou virgules entre 1 et 255 caractères"
                  onChange={(event) => {
                    setTotemAddress(event.target.value)
                  }}
                />
              </div>
              <div className="form-group">
                <label>Écran LED</label>
                <div className="form-check">
                  <label className="form-check-label" style={{ cursor: "pointer" }}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={totemIsLed}
                      onChange={() => setTotemIsLed(!totemIsLed)}
                    />
                    Mode écran LED
                  </label>
                </div>
              </div>
              <div className="form-group">
                <label>Taille de l'écran</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Largeur en pixel >= 0"
                  value={totemWidth}
                  min="0"
                  onChange={(event) => {
                    setTotemWidth(event.target.value)
                  }}
                />
                <label style={{ width: "100%", textAlign: "center" }}>x</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Hauteur en pixel >= 0"
                  value={totemHeight}
                  min="0"
                  onChange={(event) => {
                    setTotemHeight(event.target.value)
                  }}
                />
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label>Navigation interractive</label>
                <div className="form-check">
                  <label className="form-check-label" style={{ cursor: "pointer" }}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={totemHasNav}
                      onChange={() => setTotemHasNav(!totemHasNav)}
                    />
                    Activé
                  </label>
                </div>
              </div>
              {totem.establishmentTypeId === 1 && totemHasNav && (
                <div className="form-group">
                  <label>Lien de l'office de tourisme</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="https://office-de-tourisme-exemple.fr"
                    value={totemLink}
                    pattern="https:\/\/[a-zA-Z0-9:_\-.\/]{1,240}"
                    title="Lien d'un site internet commençant par https (sécurisé)"
                    required
                    onChange={(event) => {
                      setTotemLink(event.target.value)
                    }}
                  />
                </div>
              )}
              {totem.establishmentTypeId === 2 && totemHasNav && (
                <div className="form-group">
                  <label>Gestion de contenu navigation active</label>
                  <Select
                    onChange={setTotemNavTeamId}
                    options={clubs}
                    defaultValue={totemNavTeamId}
                    name="totemNavTeam"
                  />
                </div>
              )}
            </div>
            <div className="col">
              <div className="form-group">
                <label>Durée avant la veille</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder=">= 1"
                  value={totemAwakeTime}
                  min="1"
                  onChange={(event) => {
                    setTotemAwakeTime(event.target.value)
                  }}
                />
              </div>
              <div className="row">
                {powerOnOffAdvanced ? (
                  <>
                    <div className="col">
                      <div className="form-group">
                        <label>Écran On (L -&gt; D)</label>
                        <input
                          type="time"
                          className="form-control mb-1"
                          value={powerOnTimeArray[1]}
                          onChange={(event) => {
                            updateAdvancedTimeOn(1, event.target.value)
                          }}
                        />
                        <input
                          type="time"
                          className="form-control mb-1"
                          value={powerOnTimeArray[2]}
                          onChange={(event) => {
                            updateAdvancedTimeOn(2, event.target.value)
                          }}
                        />
                        <input
                          type="time"
                          className="form-control mb-1"
                          value={powerOnTimeArray[3]}
                          onChange={(event) => {
                            updateAdvancedTimeOn(3, event.target.value)
                          }}
                        />
                        <input
                          type="time"
                          className="form-control mb-1"
                          value={powerOnTimeArray[4]}
                          onChange={(event) => {
                            updateAdvancedTimeOn(4, event.target.value)
                          }}
                        />
                        <input
                          type="time"
                          className="form-control mb-1"
                          value={powerOnTimeArray[5]}
                          onChange={(event) => {
                            updateAdvancedTimeOn(5, event.target.value)
                          }}
                        />
                        <input
                          type="time"
                          className="form-control mb-1"
                          value={powerOnTimeArray[6]}
                          onChange={(event) => {
                            updateAdvancedTimeOn(6, event.target.value)
                          }}
                        />
                        <input
                          type="time"
                          className="form-control"
                          value={powerOnTimeArray[0]}
                          onChange={(event) => {
                            updateAdvancedTimeOn(0, event.target.value)
                          }}
                        />
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-group">
                        <label>Écran Off (L -&gt; D)</label>
                        <input
                          type="time"
                          className="form-control mb-1"
                          value={powerOffTimeArray[1]}
                          onChange={(event) => {
                            updateAdvancedTimeOff(1, event.target.value)
                          }}
                        />
                        <input
                          type="time"
                          className="form-control mb-1"
                          value={powerOffTimeArray[2]}
                          onChange={(event) => {
                            updateAdvancedTimeOff(2, event.target.value)
                          }}
                        />
                        <input
                          type="time"
                          className="form-control mb-1"
                          value={powerOffTimeArray[3]}
                          onChange={(event) => {
                            updateAdvancedTimeOff(3, event.target.value)
                          }}
                        />
                        <input
                          type="time"
                          className="form-control mb-1"
                          value={powerOffTimeArray[4]}
                          onChange={(event) => {
                            updateAdvancedTimeOff(4, event.target.value)
                          }}
                        />
                        <input
                          type="time"
                          className="form-control mb-1"
                          value={powerOffTimeArray[5]}
                          onChange={(event) => {
                            updateAdvancedTimeOff(5, event.target.value)
                          }}
                        />
                        <input
                          type="time"
                          className="form-control mb-1"
                          value={powerOffTimeArray[6]}
                          onChange={(event) => {
                            updateAdvancedTimeOff(6, event.target.value)
                          }}
                        />
                        <input
                          type="time"
                          className="form-control"
                          value={powerOffTimeArray[0]}
                          onChange={(event) => {
                            updateAdvancedTimeOff(0, event.target.value)
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col">
                      <div className="form-group">
                        <label>Écran On</label>
                        <input
                          type="time"
                          className="form-control"
                          value={powerOnTime}
                          onChange={(event) => {
                            setPowerOnTime(event.target.value)
                          }}
                        />
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-group">
                        <label>Écran Off</label>
                        <input
                          type="time"
                          className="form-control"
                          value={powerOffTime}
                          onChange={(event) => {
                            setPowerOffTime(event.target.value)
                          }}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="form-group">
                <label>Configuration écran</label>
                <div className="form-check">
                  <label className="form-check-label" style={{ cursor: "pointer" }}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={powerOnOffAdvanced}
                      onChange={() => setPowerOnOffAdvanced(!powerOnOffAdvanced)}
                    />
                    Avancée
                  </label>
                </div>
              </div>
            </div>
          </div>
          <p className="text-danger">{error}</p>
        </div>
        <div className="card-footer">
          <button type="submit" className="btn btn-primary">
            Valider
          </button>
          <button type="button" className="btn btn-default float-right" onClick={() => setEditTotem(false)}>
            Annuler
          </button>
        </div>
      </form>
    </div>
  )
}
